import { useAtom } from "jotai";
import { isClaimModalOpenAtom } from "../store/modal";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
//import { faMinus, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Fragment } from "react";
import OpenLogin from "@toruslabs/openlogin";

import { useTranslation } from "react-i18next";
import { getUserPrincipal } from "../service/icdragoncanister";
//import { icpAgent } from "../service/icpledgercanister";
import { Principal } from "@dfinity/principal";
import Icon, {
  PoweroffOutlined,
  CloseOutlined,
  GoogleOutlined,
} from "@ant-design/icons";

import { actorCreation } from "../service/icdragoncanister";

import { AppContext } from "../context/AppProvider";

import { Button, Modal, message } from "antd";

export default function ClaimModal() {
  const [isClaimModalOpen, setModalOpen] = useAtom(isClaimModalOpenAtom);
  const { t } = useTranslation();
  const [buying, setBuying] = useState(false);
  const [userBalance, setUserBalance] = useState(0);
  const [claiming, setClaiming] = useState(false);
  const [claimingBonus, setClaimingBonus] = useState(false);
  const [transferring, setTransferring] = useState(false);
  const [transferAddress, setTransferAddress] = useState("");
  const {
    loginInstance,
    canisterActor,
    setUserData,
    userData,
    ticketPrice,
    gameData,
    icpAgent,
    walletAddress,
  } = useContext(AppContext);
  const [ticketQuantity, setticketQuantity] = useState(10);
  const [targetAddress, setTargetAddress] = useState("");
  const [claimable, setClaimable] = useState(false);
  const [claimableB, setClaimableB] = useState(false);
  useEffect(() => {}, [loginInstance]);
  const handleMinusClick = () => {
    const newValue = Math.max(ticketQuantity - 1, 1); // Ensure the value is at least 100
    setticketQuantity(newValue);
    //dispatch(changeInvestment(newValue));
  };
  const [connected, setConnected] = useState(false);
  useEffect(() => {
    if (walletAddress && icpAgent) var b = getUserBalance();
    //console.log("user data");
    // console.log(userData)
    if (userData) {
      if (Number(userData.claimableBonus) > 0) setClaimableB(true);
      else setClaimableB(false);
      if (Number(userData.claimableReward) > 0) setClaimable(true);
      else setClaimable(false);
    } else {
      setClaimable(false);
      setClaimableB(false);
    }
    loginInstance && loginInstance.privKey && userData
      ? setConnected(true)
      : setConnected(false);
  }, [loginInstance, userData, gameData, userBalance]);
  const handlePlusClick = () => {
    var newValue = ticketQuantity + 1;

    //dispatch(changeInvestment(newValue));
    setticketQuantity(newValue);
  };

  const handleInputChange = (event) => {
    const newValue = Math.max(Number(event.target.value), 1); // Ensure the value is at least 100
    //dispatch(changeInvestment(newValue));
    setticketQuantity(newValue);
  };

  const handleAddressInputChange = (event) => {
    const newValue = event.target.value;
    //dispatch(changeInvestment(newValue));
    setTargetAddress(newValue);
    //console.log("hexstring ");
    //console.log(hexStringToByteArray(newValue));
  };

  function hexStringToByteArray(str) {
    let result = "";
    for (let i = 0; i < str.length; i += 2) {
      result += str.substring(i, i + 2);
      if (i + 2 < str.length) {
        result += "\\";
      }
    }
    return result;
  }

  useEffect(() => {
    //dispatch(changeInvestment(ticketQuantity));
  }, [ticketQuantity]);
  const closeModal = () => {
    setModalOpen(false);
  };

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    if (!loading) {
      setShowModal(false);
    }
  };

  const getUserBalance = async () => {
    //console.log("addr get balance " + walletAddress);
    //console.log(icpAgent);
    var acc = {
      owner: Principal.fromText(walletAddress),
      subaccount: [],
    };
    var balance_ = await icpAgent.icrc1_balance_of(acc);
    setUserBalance(Number(balance_) / 100000000);
    return Number(balance_);
  };

  const checkAddressType = (address_) => {
    //console.log("checking " + targetAddress);
    // Regular expressions for matching the two formats
    // Regular expression for Type 1 Address (64-character hexadecimal)
    const type1Regex = /^[a-f0-9]{64}$/i;

    // Regular expression for Type 2 Address
    // Adjust the group lengths as per the specific requirements of your address format
    const type2Regex =
      /^[a-z0-9]{5}-[a-z0-9]{5}-[a-z0-9]{5}-[a-z0-9]{5}-[a-z0-9]{5}-[a-z0-9]{5}-[a-z0-9]{5}-[a-z0-9]{5}-[a-z0-9]{5}-[a-z0-9]{5}-[a-z0-9]{3}$/i;

    const type3Regex =
      /^[a-z0-9]{5}-[a-z0-9]{5}-[a-z0-9]{5}-[a-z0-9]{5}-[a-z0-9]{3}$/i; // New Type: Example format like "s4bfy-iaaaa-aaaam-ab4qa-cai"
    if (type1Regex.test(targetAddress)) {
      //console.log("address account");
      return 1;
    } else if (type2Regex.test(targetAddress)) {
      //console.log("address principal");
      return 2;
    } else if (type3Regex.text(targetAddress)) {
      //console.log("address principal contract");
      return 2;
    } else {
      return 0;
    }
  };

  const handleclaim = async () => {
    setClaiming(true);
    //console.log("claiming");
    let res = await canisterActor.claimReward();
    //console.log("claim result");
    //console.log(res);
    var user_ = await canisterActor.getUserData();
    setUserData(user_);
    setClaiming(false);
  };

  const handleclaimBonus = async () => {
    setClaimingBonus(true);
    //console.log("claiming");
    let res = await canisterActor.claimBonusPool();
    //console.log("claim result");
    //console.log(res);
    var user_ = await canisterActor.getUserData();
    setUserData(user_);
    setClaimingBonus(false);
  };

  const handletransfer = async () => {
    var transferrableAmount = 0;
    //console.log("user balance ");
    let oriUserBalance = Number(userBalance) * 100000000;
    //console.log("user balance " + oriUserBalance < 10000);
    if (oriUserBalance < 10000) return false;
    transferrableAmount = oriUserBalance - 10000;
    setTransferring(true);
    var type_ = checkAddressType(targetAddress);
    //console.log("result check type " + type_);
    var transferResult_ = null;
    if (type_ == 1) {
      //console.log("hex ");
      //console.log(hexStringToByteArray(targetAddress));
      /*

      {
    to: IDL.Vec(IDL.Nat8),
    fee: Tokens,
    memo: IDL.Nat64,
    from_subaccount: IDL.Opt(IDL.Vec(IDL.Nat8)),
    created_at_time: IDL.Opt(TimeStamp),
    amount: Tokens,
  }
      */
      const hexString = targetAddress;
      const to_ = hexString.match(/.{1,2}/g).map((hex) => parseInt(hex, 16));
      let transferArgs_ = {
        //to: hexStringToByteArray(targetAddress),
        to: to_,
        fee: { e8s: 10000 },
        memo: 1,
        from_subaccount: [],
        created_at_time: [],
        amount: { e8s: transferrableAmount },
      };
      transferResult_ = await icpAgent.transfer(transferArgs_);
      //console.log("transfer to account");
      //console.log(transferResult_);
    } else if (type_ == 2) {
      /*
      {
    to: Account,
    fee: IDL.Opt(IDL.Nat),
    memo: IDL.Opt(IDL.Vec(IDL.Nat8)),
    from_subaccount: IDL.Opt(IDL.Vec(IDL.Nat8)),
    created_at_time: IDL.Opt(IDL.Nat64),
    amount: IDL.Nat,
  }
      */
      var acc = {
        owner: Principal.fromText(targetAddress),
        subaccount: [],
      };
      let transferArgs2_ = {
        to: acc,
        fee: [10000],
        memo: [],
        from_subaccount: [],
        created_at_time: [],
        amount: transferrableAmount,
      };
      transferResult_ = await icpAgent.icrc1_transfer(transferArgs2_);
      //console.log("transfer to principal ");
      //console.log(transferResult_);
    } else {
      console.log("address invalid");
    }
    setTransferring(false);
    var newUserData = await icpAgent.getUserBalance();
  };
  return (
    <Transition appear show={isClaimModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 w-full" onClose={closeModal}>
        {/* Overlay */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/35 w-screen" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto w-screen">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {/* Main modal */}
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-warm-white p-6 text-left align-middle shadow-xl transition-all relative">
                <Dialog.Title
                  as="h3"
                  className="text-xl  lg:text-2xl font-passion leading-6 text-gray-900 items-center text-center"
                >
                  Claim
                </Dialog.Title>

                <div className="mt-2">
                  <div className="text-sm text-gray-500">
                    <div className="w-full flex flex-col  bg-dashboard-blue p-4 rounded-2xl border border-[#1E3557]  ">
                      <div className="flex flex-col items-stretch w-full p-0 ">
                        <div className="lg:text-xl text-lg w-full p-2 grid justify-center text-center items-center lexend-light ">
                          <div className="lg:text-3xl text-2xl  font-passion  rounded-lg text-[#1E3557] lg:min-w-[200px] min-w-[100px] text-center hero-lexend">
                            {Number(userData.claimableReward) / 100000000}
                          </div>{" "}
                          claimable Dragon's Chest
                        </div>
                      </div>

                      {claiming ? (
                        <button className=" text-2xl  lg:text-[20px] px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-[#1E3557]">
                          {"Claim in Progress.."}
                        </button>
                      ) : claimable ? (
                        <button
                          onClick={handleclaim}
                          className=" text-2xl  lg:text-[20px] px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-[#1E3557]"
                        >
                          {"Claim"}
                        </button>
                      ) : (
                        <button className=" text-base  lg:text-[20px] px-6 py-3 leading-none ">
                          {"(Nothing to Claim)"}
                        </button>
                      )}
                    </div>
                    <div className="w-full flex flex-col  bg-dashboard-blue p-4 mt-4 rounded-2xl border border-[#1E3557]  ">
                      <div className="flex flex-col items-stretch w-full p-0 ">
                        <div className="lg:text-xl text-lg w-full p-2 grid justify-center text-center items-center lexend-light ">
                          <div className="lg:text-3xl text-2xl  font-passion  rounded-lg text-[#1E3557] lg:min-w-[200px] min-w-[100px] text-center hero-lexend">
                            {Number(userData.claimableBonus) / 100000000}
                          </div>{" "}
                          claimable Dwarf's Bonus Pool
                        </div>
                      </div>

                      {claimingBonus ? (
                        <button className=" text-2xl  lg:text-[20px] px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-[#1E3557]">
                          {"Claim in Progress.."}
                        </button>
                      ) : claimableB ? (
                        <button
                          onClick={handleclaimBonus}
                          className=" text-2xl  lg:text-[20px] px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-[#1E3557]"
                        >
                          {"Claim"}
                        </button>
                      ) : (
                        <button className=" text-base  lg:text-[20px] px-6 py-3 leading-none ">
                          {"(Nothing to Claim)"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-4"></div>

                {/* Close button */}
                <button
                  type="button"
                  className="absolute top-6 right-6 rounded-full"
                  onClick={closeModal}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-x"
                  >
                    <path d="M18 6 6 18" />
                    <path d="m6 6 12 12" />
                  </svg>
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
