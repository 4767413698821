import { useAtom } from "jotai";
import { isGameModalOpenAtom } from "../store/modal";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import OpenLogin from "@toruslabs/openlogin";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserPrincipal } from "../service/icdragoncanister";
import { Principal } from "@dfinity/principal";
import confetti from "canvas-confetti";
import dice from "../assets/dice_gif.gif";
import dicegif2 from "../assets/dice_gif2.gif";
import dice_1 from "../assets/dice_number/1.png";
import dice_2 from "../assets/dice_number/2.png";
import dice_3 from "../assets/dice_number/3.png";
import dice_4 from "../assets/dice_number/4.png";
import dice_5 from "../assets/dice_number/5.png";
import dice_6 from "../assets/dice_number/6.png";
import Icon, {
  PoweroffOutlined,
  CloseOutlined,
  GoogleOutlined,
} from "@ant-design/icons";

import { actorCreation } from "../service/icdragoncanister";

import { AppContext } from "../context/AppProvider";

import { Button, Modal, message } from "antd";

export default function GameModal() {
  const dice_image = [dice_1, dice_1, dice_2, dice_3, dice_4, dice_5, dice_6];
  const [isGameModalOpen, setModalOpen] = useAtom(isGameModalOpenAtom);
  const { t } = useTranslation();
  const [dice1, setDice1] = useState(1);
  const [dice2, setDice2] = useState(2);
  const [lose, setLose] = useState(false);
  const [win, setWin] = useState(false);
  const [highest, setHighest] = useState(false);
  const [absoluteHighest, setAbsoluteHighest] = useState(false);
  const [highestExtra, setHighestExtra] = useState(false);
  const [legend, setLegend] = useState(false);
  const [isextra, setIsExtra] = useState(false);
  const {
    loginInstance,
    setCanisterActor,
    setUserData,
    gameData,
    setGameData,
    userData,
    canisterActor,
    eyesLedger,
    setEyesBalance,
    walletAddress,
  } = useContext(AppContext);

  const closeModal = () => {
    setWin(false);
    setLose(false);
    setModalOpen(false);
  };

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [connected, setConnected] = useState(false);
  const [eyesRes, setEyesRes] = useState(0);
  useEffect(() => {
    if (win || legend || absoluteHighest) launchConfetti();
    loginInstance && loginInstance.privKey && userData
      ? setConnected(true)
      : setConnected(false);
  }, [loginInstance, userData, gameData, win]);

  const handleroll = async () => {
    setWin(false);
    setLose(false);
    setIsExtra(false);
    setAbsoluteHighest(false);
    setHighest(false);
    setHighestExtra(false);
    setLegend(false);
    //console.log("rolling....");
    if (connected && userData) {
      if (userData.availableDiceRoll <= 0) {
        closeModal();
        return;
      }
    }
    //console.log("rolling 2....");

    var privKey = loginInstance.privKey;
    setLoading(true);
    if (!privKey) {
      throw new Error("failed login");
    }

    const dragonAgent_ = canisterActor;

    //console.log("rolling 3....");
    var rollResult = await dragonAgent_.roll_dice(Number(gameData.ok.id));
    var roll = rollResult.result;
    var eyesGot = Number(rollResult.eyes);
    setEyesRes(eyesGot);
    //console.log("rolling 4....");
    //console.log(roll);
    if (roll.noroll) {
      //console.log("no roll");
      setLoading(false);
      return;
    }
    if (roll.lose) {
      setLose(true);
      setDice1(roll.lose[0]);
      setDice2(roll.lose[1]);
      //console.log(roll.lose[0]);
      //console.log(roll.lose[1]);
    } else if (roll.win) {
      setDice1(1);
      setDice2(1);
      setWin(true);
    } else if (roll.extra) {
      setIsExtra(true);
      setDice1(roll.extra[0]);
      setDice2(roll.extra[1]);
      //console.log(roll.extra[0]);
      //console.log(roll.extra[1]);
    } else if (roll.highest) {
      setHighest(true);
      setDice1(roll.highest[0]);
      setDice2(roll.highest[1]);
    } else if (roll.absoluteHighest) {
      setAbsoluteHighest(true);
      setDice1(6);
      setDice2(6);
    } else if (roll.highestExtra) {
      setHighestExtra(true);
      setDice1(roll.highestExtra[0]);
      setDice2(roll.highestExtra[1]);
    } else if (roll.legend) {
      setDice1(1);
      setDice2(1);
      setLegend(true);
    }

    setLoading(false);
    var user_ = await dragonAgent_.getUserData();
    var acc = {
      owner: Principal.fromText(walletAddress),
      subaccount: [],
    };
    var balance_ = await eyesLedger.icrc1_balance_of(acc);
    setEyesBalance(Number(balance_) / 100000000);

    var game_ = await dragonAgent_.getCurrentGame();

    setUserData(user_);
    setGameData(game_);
  };

  const launchConfetti = () => {
    let count = 0;
    const intervalId = setInterval(() => {
      if (count >= 5) {
        clearInterval(intervalId);
        return;
      }

      confetti({
        angle: 90,
        spread: 360,
        startVelocity: 40,
        elementCount: 200,
        dragFriction: 0.12,
        duration: 3000,
        stagger: 3,
        width: "10px",
        height: "10px",
        colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
      });

      count++;
    }, 500);
  };

  return (
    <Transition appear show={isGameModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 w-full" onClose={closeModal}>
        {/* Overlay */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25 w-screen" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto w-screen">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {/* Main modal */}
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-warm-white p-6 text-left align-middle shadow-xl transition-all relative">
                <Dialog.Title
                  as="h3"
                  className="text-xl grid  lg:text-2xl font-passion leading-6 text-[#1E3557] justify-center items-start text-center"
                >
                  Lets win{" "}
                  {gameData ? Number(gameData.ok.reward) / 100000000 : 0} ICP !
                </Dialog.Title>
                <div className="mt-2 items-center text-center">
                  {loading ? (
                    <div className="flex w-full items-center text-center justify-center">
                      <img src={dice} className="w-[150px]" />
                      <img src={dicegif2} className="w-[150px]" />
                    </div>
                  ) : (
                    <div className="flex w-full items-center text-center justify-center">
                      <img src={dice_image[dice1]} className="w-[150px]" />
                      <img src={dice_image[dice2]} className="w-[150px]" />
                    </div>
                  )}
                  {loading ? (
                    <button className=" text-2xl  lg:text-[20px] px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-[#1E3557]">
                      {"ROLLING..."}
                    </button>
                  ) : (
                    <button
                      onClick={handleroll}
                      className=" text-2xl  lg:text-[20px] px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-[#1E3557]"
                    >
                      {"ROLL!"}
                    </button>
                  )}
                  <p className="pt-2">
                    {" "}
                    You have{" "}
                    {connected && userData
                      ? Number(userData.availableDiceRoll)
                      : 0}{" "}
                    {Number(userData.availableDiceRoll) > 1
                      ? "tickets"
                      : "ticket"}
                    {" remaining "}
                  </p>
                </div>
                <div className="lg:min-h-[80px]">
                  {win ? (
                    <div className="items-center pt-4 text-center font-passero text-2xl">
                      You win, warrior!
                      <br /> The treasure is now yours.
                      <br />
                      <br />
                      You got : {eyesRes} $EYES
                    </div>
                  ) : (
                    <div className="flex"></div>
                  )}
                  {lose ? (
                    <div className="items-center pt-4 text-center font-passero text-2xl">
                      You lose, padawan. <br />
                      The treasure chest is now heavier. <br />
                      <br />
                      You got : {eyesRes} $EYES
                    </div>
                  ) : (
                    <div className="flex"></div>
                  )}
                  {isextra ? (
                    <div className="items-center pt-4 text-center font-passero text-2xl">
                      Its a double! <br />
                      Your luck is strong and you got an extra roll.
                      <br />
                      another chance to win for free.
                      <br />
                      <br />
                      You got : {eyesRes} $EYES
                    </div>
                  ) : (
                    <div className="flex"></div>
                  )}
                  {highest ? (
                    <div className="items-center pt-4 text-center font-passero text-2xl">
                      You got the highest dice so far.
                      <br />
                      Dwarf’s Chest will be yours if no one get higher number
                      than you!
                      <br />
                      Share to increase your prize <br />
                      <br />
                      You got : {eyesRes} $EYES
                    </div>
                  ) : (
                    <div className="flex"></div>
                  )}
                  {absoluteHighest ? (
                    <div className="items-center pt-4 text-center font-passero text-2xl">
                      You got double six and guaranteed won the Dwarf’s chest!
                      <br />
                      Every dice roll from now will increase your prize. <br />
                      You may redeem your prize after someone win the Dragon’s
                      chest.
                      <br />
                      Share to increase your prize
                      <br />
                      <br />
                      You got : {eyesRes} $EYES
                      <br />
                    </div>
                  ) : (
                    <div className="flex"></div>
                  )}
                  {highestExtra ? (
                    <div className="items-center pt-4 text-center font-passero text-2xl">
                      You got the highest dice so far, and an extra roll!
                      <br />
                      Dwarf’s Chest will be yours if no one get higher number
                      than you!
                      <br />
                      Share to increase your prize
                      <br />
                      <br />
                      You got : {eyesRes} $EYES
                    </div>
                  ) : (
                    <div className="flex"></div>
                  )}
                  {legend ? (
                    <div className="items-center pt-4 text-center font-passero text-2xl">
                      YOU ARE A LEGEND! <br />
                      Your you WIN both Dragon and Dwarf's chest
                      <br /> The treasure is now yours.
                      <br />
                      <br />
                      You got : {eyesRes} $EYES
                    </div>
                  ) : (
                    <div className="flex"></div>
                  )}
                </div>
                <div className="mt-4"></div>

                {/* Close button */}
                <button
                  type="button"
                  className="absolute top-6 right-6 rounded-full"
                  onClick={closeModal}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-x"
                  >
                    <path d="M18 6 6 18" />
                    <path d="m6 6 12 12" />
                  </svg>
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
