import React from "react";

import { useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Row, Col, Typography, Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import LokaBackground from "../../assets/image/loka_bg.png";
import Header from "../../components/Header";
import GameModal from "../../components/GameModal";
import HistoryModal from "../../components/HistoryModal";
import moneyBag from "../../assets/dragon_base_.png";
import moneyBag2 from "../../assets/dragon_sm.png";
import dragonMobile from "../../assets/dragon_mobile.png";
import { Principal } from "@dfinity/principal";
import { AccountIdentifier } from "@dfinity/ledger-icp";
import bottom_chest from "../../assets/bottom_chest.png";
import dice from "../../assets/dice.png";
import loka_icon from "../../assets/icon/loka_icon.png";
import icp_logo from "../../assets/small_icp.png";
import ConnectModal from "../../components/ConnectModal";
import MigrateModal from "../../components/MigrateModal";
import { AppContext } from "../../context/AppProvider";
import confetti from "canvas-confetti";
import "./style.css";
import { useEffect, useState, useContext } from "react";
import { FaTwitter } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import {
  isMigrateModalOpenAtom,
  isModalOpenAtom,
  isBuyModalOpenAtom,
  isGuideModalOpenAtom,
} from "../../store/modal";
import { actorCreation } from "../../service/icdragoncanister";
import BuyTicketModal from "../../components/BuyTicketModal";
import ClaimModal from "../../components/ClaimModal";
import TransferModal from "../../components/TransferModal";
import GuideModal from "../../components/GuideModal";
import { getUserPrincipal } from "../../service/icdragoncanister";
import { eyesCreation } from "../../service/eyesledgercanister";
import { icpAgent } from "../../service/icpledgercanister";
import { icpAgent as icpAgentCreation } from "../../service/icpledgercanister";
import { Modal, message } from "antd";
import { useAtom } from "jotai";
import { wait } from "@testing-library/user-event/dist/utils";
const { Title } = Typography;

const Migrate = () => {
  const [isModalOpen, setModalOpen] = useAtom(isMigrateModalOpenAtom);
  const [first, setFirst] = useState(false);
  const [oldWalletAddress, setOldWalletAddress] = useState(false);
  const [eyesReward, setEyesReward] = useState(0);
  const [oldWalletData, setOldWalletData] = useState(false);
  const [oldEmail, setOldEmail] = useState(false);
  const { t } = useTranslation();
  const [oldEyes, setOldEyes] = useState(false);
  const [migration, setMigration] = useState(false);
  const [checking, setChecking] = useState(false);
  const [disc, setDisc] = useState(true);
  const {
    oldLogin,
    newLogin,
    setUserData,
    loginInstance,
    setWalletAddress,
    setICPAgent,
    setEyesLedger,
    eyesLedger,
    setCurrentEmail,
    setEyesBalance,
    setToggleMobileMenu,
    currentEmail,
    setWalletAlias,
  } = useContext(AppContext);

  const {
    canisterActor,
    setCanisterActor,
    gameData,
    userData,
    ticketPrice,
    setGameData,
    setTicketPrice,
    walletAddress,
  } = useContext(AppContext);
  const [connected, setConnected] = useState(false);
  const [halving, setHalving] = useState(false);
  const [forceLogout, setForcelogout] = useState(true);

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    //if (first) launchConfetti();
    //setFirst(false);
  }, [oldWalletAddress]);

  const launchConfetti = () => {
    let count = 0;
    const intervalId = setInterval(() => {
      if (count >= 5) {
        clearInterval(intervalId);
        return;
      }

      confetti({
        angle: 90,
        spread: 360,
        startVelocity: 40,
        elementCount: 200,
        dragFriction: 0.12,
        duration: 3000,
        stagger: 3,
        width: "10px",
        height: "10px",
        colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
      });

      count++;
    }, 500);
  };

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newPrivKey, setNewPrivKey] = useState(false);
  const [oldPrivKey, setOldPrivKey] = useState(false);

  const handleCloseModal = () => {
    if (!loading) {
      setShowModal(false);
    }
  };

  async function waitLogout() {
    await handleLogout();
    setForcelogout(false);
  }

  async function handleReconnect() {
    var a = await handleLogout();
    // var b = await handleLogin();
    setLoading(true);
    try {
      const { privKey } = await loginInstance.login({
        loginProvider: "google",
        redirectUrl: `${window.origin}`,
      });

      if (!privKey) {
        throw new Error("failed login");
      }
      setNewPrivKey(privKey);
      setCurrentEmail(loginInstance.getUserInfo().email);

      //console.log("privkey");
      //console.log(privKey);
      const actor = actorCreation(privKey);
      const icpAgent_ = icpAgent(privKey);
      const eyes_ = eyesCreation(privKey);
      var principalString_ = getUserPrincipal(privKey).toString();
      //console.log("user data " + JSON.stringify(userData_));
      //console.log("initial address " + principalString_);
      setCanisterActor(actor);
      //console.log(icpAgent_);
      setICPAgent(icpAgent_);
      setEyesLedger(eyes_);
      //console.log("eyes canister ");
      //console.log(eyes_);
      var user_ = await actor.getUserData();
      var game_ = await actor.getCurrentGame();
      var ticket_ = await actor.getTicketPrice();
      //var reward_ = await actor.initialEyesTokenCheck();
      // var rwd = Number(reward_) / 100000000;
      // setEyesReward(rwd);
      //var frst = false;
      // if (Number(reward_) > 1) {
      // setFirst(true);
      // frst = true;
      // }
      //console.log("game");
      //console.log(game_);
      setUserData(user_);
      setGameData(game_);
      setTicketPrice(ticket_);
      setLoading(false);
      setWalletAddress(principalString_);
      setWalletAlias(user_.alias.toString());

      setShowModal(false);
      setToggleMobileMenu(false);
    } catch (error) {
      setLoading(false);
      setShowModal(false);
      message.error("login failed");
    }
  }
  async function handleConnectOldWallet() {
    setChecking(true);
    setLoading(true);
    try {
      const { privKey } = await oldLogin.login({
        loginProvider: "google",
        redirectUrl: `${window.origin}`,
      });

      if (!privKey) {
        throw new Error("failed login");
      }
      setOldPrivKey(privKey);
      var principalString_ = getUserPrincipal(privKey).toString();

      setOldWalletAddress(principalString_);

      var isMigrate_ = await canisterActor.isMigrateable(principalString_);

      setOldWalletData(isMigrate_);

      var accOld = {
        owner: Principal.fromText(principalString_),
        subaccount: [],
      };

      var eyesOld_ = eyesCreation(privKey);
      //var icpAgent_ = icpAgentCreation(privKey);

      const dragonAgent_ = canisterActor;

      var eyesBalance_ = await eyesOld_.icrc1_balance_of(accOld);
      //var icpBalance_ = await icpAgent_.icrc1_balance_of(accOld);
      // closeModal();
      // if (!frst) closeModal();
      setOldEmail(oldLogin.getUserInfo().email);
      setOldEyes(Number(eyesBalance_));
      setChecking(false);
      await oldLogin.logout();
    } catch (error) {
      setLoading(false);
      setShowModal(false);
      message.error("login failed");
    }
  }
  async function executeMigration() {
    setLoading(true);
    setMigration(true);
    var privKey = oldPrivKey;

    if (!privKey) {
      throw new Error("failed login");
    }

    var accNow = {
      owner: Principal.fromText(walletAddress),
      subaccount: [],
    };

    var accOld = {
      owner: Principal.fromText(oldWalletAddress),
      subaccount: [],
    };

    const dragonAgent_ = actorCreation(newPrivKey);
    const actor = actorCreation(newPrivKey);
    var migrate_ = await dragonAgent_.migrate(oldWalletAddress);

    var eyesOld_ = eyesCreation(oldPrivKey);
    var eyesBalance_ = await eyesOld_.icrc1_balance_of(accOld);

    let transferEyesFromOldWallet = {
      to: accNow,
      fee: [],
      memo: [],
      from_subaccount: [],
      created_at_time: [],
      amount: Number(eyesBalance_),
    };
    if (Number(eyesBalance_) > 0)
      var transferResult_ = await eyesOld_.icrc1_transfer(
        transferEyesFromOldWallet
      );

    eyesBalance_ = await eyesOld_.icrc1_balance_of(accNow);

    setEyesBalance(Number(eyesBalance_) / 100000000);
    var user_ = await dragonAgent_.getUserData();
    setUserData(user_);

    setOldWalletAddress(false);
    setOldWalletData(false);
    setCurrentEmail(false);
    setOldEmail(false);
    setMigration(false);
    var c = await handleLogout();
  }

  async function handleLogin() {
    setLoading(true);
    try {
      const { privKey } = await loginInstance.login({
        loginProvider: "google",
        redirectUrl: `${window.origin}`,
      });

      if (!privKey) {
        throw new Error("failed login");
      }

      setCurrentEmail(loginInstance.getUserInfo().email);

      //console.log("privkey");
      //console.log(privKey);
      const actor = actorCreation(privKey);
      const icpAgent_ = icpAgent(privKey);
      const eyes_ = eyesCreation(privKey);
      var principalString_ = getUserPrincipal(privKey).toString();
      //console.log("user data " + JSON.stringify(userData_));
      //console.log("initial address " + principalString_);
      setCanisterActor(actor);
      //console.log(icpAgent_);
      setICPAgent(icpAgent_);
      setEyesLedger(eyes_);
      //console.log("eyes canister ");
      //console.log(eyes_);
      var user_ = await actor.getUserData();
      var game_ = await actor.getCurrentGame();
      var ticket_ = await actor.getTicketPrice();
      //var reward_ = await actor.initialEyesTokenCheck();
      // var rwd = Number(reward_) / 100000000;
      // setEyesReward(rwd);
      //var frst = false;
      // if (Number(reward_) > 1) {
      // setFirst(true);
      // frst = true;
      // }
      //console.log("game");
      //console.log(game_);
      setUserData(user_);
      setGameData(game_);
      setTicketPrice(ticket_);
      setLoading(false);
      setWalletAddress(principalString_);
      setWalletAlias(user_.alias.toString());

      setShowModal(false);
      setToggleMobileMenu(false);
      // closeModal();
      // if (!frst) closeModal();
    } catch (error) {
      setLoading(false);
      setShowModal(false);
      message.error("login failed");
    }
  }

  const handleLogout = async () => {
    //setLoading(true);
    setWalletAddress(false);
    setToggleMobileMenu(false);
    setCanisterActor(false);
    setUserData(false);
    console.log("loggin out");
    await loginInstance.logout();

    //setGameData(false);

    //setLoading(false);
  };

  const setMigrateModalOpen = useSetAtom(isMigrateModalOpenAtom);
  const setConnectOpen = useSetAtom(isModalOpenAtom);
  const setBuyOpen = useSetAtom(isBuyModalOpenAtom);
  const setGuideOpen = useSetAtom(isGuideModalOpenAtom);
  const generalPrivKey =
    "0bc9866cbc181a4f5291476f7be00ca4f11cae6787e10ed9dc1d40db7943f643";
  const [num, setNum] = useState(250);

  /**
   * for demo purposes to show dynamic stash text
   * You can delete this
   */

  const getUserDatas = async () => {
    //const actor = actorCreation(loginInstance.privKey);
    //const actor = actorCreation(generalPrivKey);
  };

  const getGameDatas = async () => {
    const actor = actorCreation(generalPrivKey);
    let game_ = await actor.getCurrentGame();
    let ticket_ = await actor.getTicketPrice();
    let halving_ = await actor.getNextHalving();
    setHalving(Number(halving_));
    setTicketPrice(ticket_);
    setGameData(game_);
  };

  useEffect(() => {
    getGameDatas();
  }, [generalPrivKey]);

  useEffect(() => {
    // Call the async function
  }, [loginInstance, walletAddress]);

  useEffect(() => {
    if (connected) {
      getUserDatas();
    }
  }, [connected]);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1080px)",
  });

  useEffect(() => {}, [halving]);
  return (
    <div className="landing-container h-screen">
      {isDesktopOrLaptop ? (
        <div>
          <Header className="z-999999" />
          <section>
            <div className="w-full flex flex-col items-center ">
              {/* Hero */}
              <div className="flex flex-col w-full  relative items-center px-5 lg:px-5 gap-10 lg:gap-0">
                {/* Money bag */}
                <div className="w-full lg:absolute  relative z-10">
                  <div className="relative flex items-center justify-center ">
                    <img
                      src={moneyBag}
                      className="h-screen w-screen  scale-125 lg:scale-100 hidden lg:flex"
                    />
                    <img
                      src={moneyBag2}
                      className="w-screen h-screen object-cover scale-100 lg:scale-100  lg:hidden"
                    />
                  </div>
                  {/* Roll em now */}
                  <div className="absolute flex top-[10%] font-passion text-3xl w-full text-center items-center justify-center ">
                    <div className="w-[30%] bg-warm-white rounded-3xl text-dark-blue">
                      <div className="p-2 pt-5"> Migrate Data</div>
                      <div className="p-8 text-base">
                        {" "}
                        For those who connected before 31 Jan 2024, highly
                        recommended to migrate your assets and data to new
                        address
                      </div>

                      <div className="text-lg grid items-center justify-center text-center p-1">
                        <div className="text-xl">
                          Its super easy! Just follow these secure and reliable
                          steps :
                        </div>
                        {walletAddress ? (
                          <>
                            <div className="p-4 mt-5 items-center justify-center text-center border border-dark-blue rounded-3xl">
                              <div>1. Reconnect to Dragon Eyes 🐉 👀</div>
                              <div className=" flex items-center justify-center text-center">
                                {!currentEmail ? (
                                  <div
                                    className="rounded-2xl bg-dark-blue p-2 text-xl cursor-pointer text-white w-[100px]"
                                    onClick={() => handleReconnect()}
                                  >
                                    Reconnect
                                  </div>
                                ) : (
                                  <div className="text-2xl text-bright-red">
                                    Reconnected, continue to the next step
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="mt-2 p-4 items-center justify-center text-center border border-dark-blue rounded-3xl">
                              {" "}
                              <div>
                                2. Connect to old wallet (must use the same
                                email)
                              </div>
                              <div className=" flex items-center justify-center text-center">
                                {currentEmail == oldEmail &&
                                currentEmail != false ? (
                                  <div className="text-2xl text-bright-red">
                                    Old Wallet connected
                                    <br />
                                    <span className="text-sm">
                                      {oldWalletAddress}
                                    </span>
                                  </div>
                                ) : checking ? (
                                  <div className="rounded-2xl  p-2 text-xl cursor-pointer text-dark-blue w-[180px]">
                                    Automagically Checking...
                                  </div>
                                ) : (
                                  <div
                                    className="rounded-2xl bg-gray-300 p-2 text-xl cursor-pointer text-dark-blue w-[180px]"
                                    onClick={() => handleConnectOldWallet()}
                                  >
                                    Connect to Old Wallet
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="mt-2 p-4 items-center justify-center text-center border border-dark-blue rounded-3xl">
                              {" "}
                              <div>
                                3. Automagically check if you have any data
                              </div>
                              <div className=" flex items-center justify-center text-center">
                                {currentEmail == oldEmail &&
                                currentEmail != false &&
                                oldWalletData.ok ? (
                                  <div className="flex">
                                    <div className="grid justify-center items-center">
                                      <div className="flex justify-center items-center">
                                        <div className="text-xl text-dark-blue">
                                          Dice Ticket :
                                        </div>
                                        <div className="text-2xl text-bright-red">
                                          {Number(
                                            oldWalletData.ok.availableDiceRoll
                                          )}
                                        </div>
                                      </div>
                                      <div className="flex justify-center items-center">
                                        <div className="text-xl text-dark-blue">
                                          $EYES :
                                        </div>
                                        <div className="text-2xl text-bright-red">
                                          {(
                                            oldEyes / 100000000
                                          ).toLocaleString()}
                                        </div>
                                      </div>
                                      <div className="flex justify-center items-center">
                                        <div className="text-xl text-dark-blue">
                                          claimable Dragon's Chest :
                                        </div>
                                        <div className="text-2xl text-bright-red">
                                          {parseInt(
                                            Number(
                                              oldWalletData.ok.claimableReward
                                            ) / 100000000
                                          ).toLocaleString()}{" "}
                                          ICP
                                        </div>
                                      </div>
                                      <div className="flex justify-center items-center">
                                        <div className="text-xl text-dark-blue">
                                          claimable Dwarf's bonus :
                                        </div>
                                        <div className="text-2xl text-bright-red">
                                          {parseInt(
                                            Number(
                                              oldWalletData.ok.claimableBonus
                                            ) / 100000000
                                          ).toLocaleString()}{" "}
                                          ICP
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="rounded-2xl  p-2 text-lg cursor-pointer text-dark-blue">
                                    {oldWalletData.none ? (
                                      <div className="text-2xl text-bright-red">
                                        No Migration Needed for this wallet
                                      </div>
                                    ) : (
                                      "No Data"
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div>
                              {currentEmail == oldEmail &&
                              currentEmail != false &&
                              oldWalletData &&
                              !oldWalletData.none ? (
                                <>
                                  <button
                                    className="bg-bright-red rounded-xl text-warm-white text-2xl font-passion p-2 px-4 "
                                    onClick={() => executeMigration()}
                                  >
                                    {!migration
                                      ? "Migrate Now"
                                      : "Migration in progress..."}
                                  </button>
                                  <br />
                                  <span className="text-lg">
                                    *will auto disconnect once completed
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="p-4 mt-5 items-center justify-center text-center border border-dark-blue rounded-3xl">
                              <div>Connect Wallet to Dragon Eyes 🐉 👀</div>
                            </div>
                          </>
                        )}
                        <a href="/" className="mt-10">
                          <button className="bg-dark-blue rounded-xl text-warm-white text-2xl font-passion p-2 px-4  ">
                            Back to Home
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <MigrateModal />
          <ConnectModal className="z-70" />
          <GameModal className="z-70" />
          <BuyTicketModal className="z-70" />
          <ClaimModal className="z-70" />
          <TransferModal className="z-70" />
          <HistoryModal className="z-70" />
          <GuideModal className="z-70" />
        </div>
      ) : (
        <div
          className="h-screen  bg-cover bg-center"
          style={{ backgroundImage: `url(${dragonMobile})` }}
        >
          <Header className="z-999999" />
          <div className="w-full  lg:hidden items-center justify-center text-center px-6   z-10">
            <div className="z-40 w-full  py-2  rounded-lg px-0 flex flex-col gap-3">
              <div className="flex font-passion text-3xl w-full text-center items-center justify-center ">
                <div className="w-full bg-warm-white rounded-3xl text-dark-blue">
                  <div className="p-2"> Migrate Data</div>
                  <div className="p-2 text-base">
                    {" "}
                    For those who connected before 31 Jan 2024, highly
                    recommended to migrate your assets and data to new address
                  </div>
                  <div className="text-lg grid items-center justify-center text-center p-5">
                    <div className="text-xl">
                      Its super easy! Just follow these secure and reliable
                      steps :
                    </div>
                    {walletAddress ? (
                      <>
                        <div className="mt-2 p-4 items-center justify-center text-center border border-dark-blue rounded-3xl">
                          <div>1. Reconnect to Dragon Eyes 🐉 👀</div>
                          <div className=" flex items-center justify-center text-center">
                            {!currentEmail ? (
                              <div
                                className="rounded-2xl bg-dark-blue p-2 text-xl cursor-pointer text-white w-[100px]"
                                onClick={() => handleReconnect()}
                              >
                                Reconnect
                              </div>
                            ) : (
                              <div className="text-2xl text-bright-red">
                                Reconnected, continue to the next step
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mt-2 p-4 items-center justify-center text-center border border-dark-blue rounded-3xl">
                          {" "}
                          <div>
                            2. Connect to old wallet (must use the same email)
                          </div>
                          <div className=" flex items-center justify-center text-center">
                            {currentEmail == oldEmail &&
                            currentEmail != false ? (
                              <div className="text-2xl text-bright-red">
                                Old Wallet connected
                                <br />
                                <span className="text-sm">
                                  {oldWalletAddress}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="rounded-2xl bg-gray-300 p-2 text-xl cursor-pointer text-dark-blue w-[180px]"
                                onClick={() => handleConnectOldWallet()}
                              >
                                Connect to Old Wallet
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mt-2 p-4 items-center justify-center text-center border border-dark-blue rounded-3xl">
                          {" "}
                          <div>3. Automagically check if you have any data</div>
                          <div className=" flex items-center justify-center text-center">
                            {currentEmail == oldEmail &&
                            currentEmail != false &&
                            oldWalletData.ok ? (
                              <div className="flex">
                                <div className="grid justify-center items-center">
                                  <div className="flex justify-center items-center">
                                    <div className="text-xl text-dark-blue">
                                      Dice Ticket :
                                    </div>
                                    <div className="text-2xl text-bright-red">
                                      {Number(
                                        oldWalletData.ok.availableDiceRoll
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex justify-center items-center">
                                    <div className="text-xl text-dark-blue">
                                      $EYES :
                                    </div>
                                    <div className="text-2xl text-bright-red">
                                      {(oldEyes / 100000000).toLocaleString()}
                                    </div>
                                  </div>
                                  <div className="flex justify-center items-center">
                                    <div className="text-xl text-dark-blue">
                                      claimable Dragon's Chest :
                                    </div>
                                    <div className="text-2xl text-bright-red">
                                      {parseInt(
                                        Number(
                                          oldWalletData.ok.claimableReward
                                        ) / 100000000
                                      ).toLocaleString()}{" "}
                                      ICP
                                    </div>
                                  </div>
                                  <div className="flex justify-center items-center">
                                    <div className="text-xl text-dark-blue">
                                      claimable Dwarf's bonus :
                                    </div>
                                    <div className="text-2xl text-bright-red">
                                      {parseInt(
                                        Number(
                                          oldWalletData.ok.claimableBonus
                                        ) / 100000000
                                      ).toLocaleString()}{" "}
                                      ICP
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="rounded-2xl  p-2 text-lg cursor-pointer text-dark-blue">
                                {oldWalletData.none ? (
                                  <div className="text-2xl text-bright-red">
                                    No Migration Needed for this wallet
                                  </div>
                                ) : (
                                  "No Data"
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div>
                          {currentEmail == oldEmail &&
                          currentEmail != false &&
                          oldWalletData &&
                          !oldWalletData.none ? (
                            <button
                              className="bg-dark-blue rounded-xl text-warm-white text-2xl font-passion p-2 px-4 "
                              onClick={() => executeMigration()}
                            >
                              {!migration
                                ? "Migrate Now"
                                : "Migration in progress..."}
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <div className="p-4 mt-5 items-center justify-center text-center border border-dark-blue rounded-3xl">
                          <div>Connect Wallet to Dragon Eyes 🐉 👀</div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <a href="/" className="mt-10">
                <button className="bg-dark-blue rounded-xl text-warm-white text-2xl font-passion p-2 px-4 ">
                  Back to Home
                </button>
              </a>
            </div>
          </div>

          {/* */}
        </div>
      )}
    </div>
  );
};

export default Migrate;
